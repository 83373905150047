import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { css } from '@emotion/core';

import {
  Pump,
  Pump2,
  ValveConnect,
  Pipe,
  PipePlaceholder,
  IRCompressor,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  PumpsRowMedium,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  MobileStatTable,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
// import { LanguageContext } from 'isno/lib/components/language';
import { fetchLocation, setIOValue, fetchPLC } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  highSuctionPressure: 'high_system_suction_water_pressure',
  highDischargeWaterPressure: 'high_system_discharge_water_pressure',
  highBackWaterPressure: 'high_system_back_water_pressure',
  medDischargeWaterPressure: 'medium_system_discharge_water_pressure',
  medBackWaterPressure: 'medium_system_back_water_pressure',
  medSuctionWaterPressure: 'medium_system_suction_water_pressure',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  highSealTemperature: 'high_seal_temperature',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',

  cv1Position: 'cv1_position',
  cv2Position: 'cv2_position',
  bv1Position: 'bv1_position',
  bv2Position: 'bv2_position',
  dischargeAirPressure: 'discharge_air_pressure',
  dischargeAirFlow: 'discharge_air_flow',
  coolingSysPressure: 'cooling_system_pressure',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function BoosterScreen(props) {
  const theme = useTheme();
  // const language = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(3), // 12
        props.fetchPLC(2),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchPLC(2);
    props.fetchLocation(3); // 12
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  const locationEquipment12 = props.locations?.locations?.[3]?.equipment; // 12
  const fakeLocationEquipment = {
    Other: {
      id: 13,
      locationId: 3,
      plcId: 2,
      plc: {
        id: 2,
        name: 'Booster',
        channel: 'Channel101',
        ipAddress: '<10.75.10.107>',
        model: null,
        isSimulated: false,
        hasError: false,
        isEnabled: true,
        ioValues: null,
        dateModified: '2022-12-04T17:30:06.300587Z',
      },
      equipmentTypeId: -1,
      equipmentType: {
        id: -1,
        name: 'other',
        description: 'None',
        equipment: {},
        dateModified: '2022-11-23T22:59:04.099104Z',
      },
      name: 'Other',
      longitude: null,
      latitude: null,
      voltageRating: null,
      powerFactor: null,
      amperageLevels: null,
      ioValues: {
        medium_system_low_suction_pressure: {
          id: 45,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -1,
          ioValueType: {
            id: -1,
            name: 'alarm',
            units: null,
            description: 'Alarm Sensor',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag100',
          address: null,
          dataType: null,
          name: 'medium_system_low_suction_pressure',
          description: 'medium_system_low_suction_pressure alarm',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618277Z',
        },
        high_system_low_suction_pressure: {
          id: 46,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -1,
          ioValueType: {
            id: -1,
            name: 'alarm',
            units: null,
            description: 'Alarm Sensor',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag104',
          address: null,
          dataType: null,
          name: 'high_system_low_suction_pressure',
          description: 'high_system_low_suction_pressure alarm',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618277Z',
        },
        master_alarm: {
          id: 47,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -3,
          ioValueType: {
            id: -3,
            name: 'status',
            units: null,
            description: 'Boolean Status',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag132',
          address: null,
          dataType: null,
          name: 'master_alarm',
          description: 'master_alarm status',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618277Z',
        },
        alarm_reset: {
          id: 48,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -6,
          ioValueType: {
            id: -6,
            name: 'toggle_on',
            units: null,
            description: 'Set to 1',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag133',
          address: null,
          dataType: null,
          name: 'alarm_reset',
          description: 'alarm_reset toggle_on',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618276Z',
        },
        medium_system_discharge_water_pressure: {
          id: 49,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -10,
          ioValueType: {
            id: -10,
            name: 'psi',
            units: 'psi',
            description: 'US Pressure',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag211',
          address: null,
          dataType: null,
          name: 'medium_system_discharge_water_pressure',
          description: 'medium_system_discharge_water_pressure psi',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618276Z',
        },
        medium_system_back_water_pressure: {
          id: 50,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -10,
          ioValueType: {
            id: -10,
            name: 'psi',
            units: 'psi',
            description: 'US Pressure',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag212',
          address: null,
          dataType: null,
          name: 'medium_system_back_water_pressure',
          description: 'medium_system_back_water_pressure psi',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618276Z',
        },
        high_system_discharge_water_pressure: {
          id: 51,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -10,
          ioValueType: {
            id: -10,
            name: 'psi',
            units: 'psi',
            description: 'US Pressure',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag213',
          address: null,
          dataType: null,
          name: 'high_system_discharge_water_pressure',
          description: 'high_system_discharge_water_pressure psi',
          value: 1.6422993,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618275Z',
        },
        medium_system_suction_water_pressure: {
          id: 52,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -10,
          ioValueType: {
            id: -10,
            name: 'psi',
            units: 'psi',
            description: 'US Pressure',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag214',
          address: null,
          dataType: null,
          name: 'medium_system_suction_water_pressure',
          description: 'medium_system_suction_water_pressure psi',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618275Z',
        },
        high_system_suction_water_pressure: {
          id: 53,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -10,
          ioValueType: {
            id: -10,
            name: 'psi',
            units: 'psi',
            description: 'US Pressure',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag217',
          address: null,
          dataType: null,
          name: 'high_system_suction_water_pressure',
          description: 'high_system_suction_water_pressure psi',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618275Z',
        },
        high_system_back_water_pressure: {
          id: 54,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -10,
          ioValueType: {
            id: -10,
            name: 'psi',
            units: 'psi',
            description: 'US Pressure',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag218',
          address: null,
          dataType: null,
          name: 'high_system_back_water_pressure',
          description: 'high_system_back_water_pressure psi',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618274Z',
        },
        cv1_position: {
          id: 55,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -37,
          ioValueType: {
            id: -37,
            name: 'percent',
            units: '%',
            description: 'Percent',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag230',
          address: null,
          dataType: null,
          name: 'cv1_position',
          description: 'cv1_position percent',
          value: 100.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618274Z',
        },
        cv2_position: {
          id: 56,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -37,
          ioValueType: {
            id: -37,
            name: 'percent',
            units: '%',
            description: 'Percent',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag238',
          address: null,
          dataType: null,
          name: 'cv2_position',
          description: 'cv2_position percent',
          value: 100.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618274Z',
        },
        bv1_position: {
          id: 57,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -37,
          ioValueType: {
            id: -37,
            name: 'percent',
            units: '%',
            description: 'Percent',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag243',
          address: null,
          dataType: null,
          name: 'bv1_position',
          description: 'bv1_position percent',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618273Z',
        },
        bv2_position: {
          id: 58,
          plc: null,
          location: null,
          equipmentId: 13,
          ioValueTypeId: -37,
          ioValueType: {
            id: -37,
            name: 'percent',
            units: '%',
            description: 'Percent',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag248',
          address: null,
          dataType: null,
          name: 'bv2_position',
          description: 'bv2_position percent',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618273Z',
        },
      },
      status: null,
      textStatus: null,
      textStatusKey: null,
      wetbulb: null,
      dateModified: '2022-11-23T17:59:06.42082Z',
    },
    P5: {
      id: 14,
      locationId: 3,
      plcId: 2,
      plc: {
        id: 2,
        name: 'Booster',
        channel: 'Channel101',
        ipAddress: '<10.75.10.107>',
        model: null,
        isSimulated: false,
        hasError: false,
        isEnabled: true,
        ioValues: null,
        dateModified: '2022-12-04T17:30:06.300587Z',
      },
      equipmentTypeId: -2,
      equipmentType: {
        id: -2,
        name: 'pump',
        description: 'Vertical Pump',
        equipment: {},
        dateModified: '2022-11-23T22:59:04.099104Z',
      },
      name: '',
      longitude: null,
      latitude: null,
      voltageRating: 480.000000,
      powerFactor: 0.920000,
      amperageLevels: null,
      ioValues: {
        low_suction_pressure: {
          id: 59,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -1,
          ioValueType: {
            id: -1,
            name: 'alarm',
            units: null,
            description: 'Alarm Sensor',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag101',
          address: null,
          dataType: null,
          name: 'low_suction_pressure',
          description: 'low_suction_pressure alarm',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618273Z',
        },
        high_seal_temperature: {
          id: 60,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -1,
          ioValueType: {
            id: -1,
            name: 'alarm',
            units: null,
            description: 'Alarm Sensor',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag121',
          address: null,
          dataType: null,
          name: 'high_seal_temperature',
          description: 'high_seal_temperature alarm',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618272Z',
        },
        start: {
          id: 61,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -6,
          ioValueType: {
            id: -6,
            name: 'toggle_on',
            units: null,
            description: 'Set to 1',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag147',
          address: null,
          dataType: null,
          name: 'start',
          description: 'start toggle_on',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618272Z',
        },
        stop: {
          id: 62,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -6,
          ioValueType: {
            id: -6,
            name: 'toggle_on',
            units: null,
            description: 'Set to 1',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag179',
          address: null,
          dataType: null,
          name: 'stop',
          description: 'stop toggle_on',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618271Z',
        },
        current: {
          id: 63,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -16,
          ioValueType: {
            id: -16,
            name: 'amps',
            units: 'amps',
            description: 'Current in Amperes',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag215',
          address: null,
          dataType: null,
          name: 'current',
          description: 'current amps',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618271Z',
        },
        run_time: {
          id: 65,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -20,
          ioValueType: {
            id: -20,
            name: 'hours',
            units: 'hrs',
            description: 'Elapsed Time',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag249',
          address: null,
          dataType: null,
          name: 'run_time',
          description: 'run_time hours',
          value: 0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.61827Z',
        },
        temperature_alternate: {
          id: 771,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -27,
          ioValueType: {
            id: -27,
            name: 'celsius',
            units: '°C',
            description: 'International Temperature',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: null,
          address: null,
          dataType: null,
          name: 'temperature_alternate',
          description: 'temperature_alternate celsius',
          value: 15.610000,
          source: 1,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-12-08T17:41:28.058474Z',
        },
      },
      status: 0,
      textStatus: null,
      textStatusKey: null,
      wetbulb: null,
      dateModified: '2022-11-23T17:59:06.42082Z',
    },
    P8: {
      id: 14,
      locationId: 3,
      plcId: 2,
      plc: {
        id: 2,
        name: 'Booster',
        channel: 'Channel101',
        ipAddress: '<10.75.10.107>',
        model: null,
        isSimulated: false,
        hasError: false,
        isEnabled: true,
        ioValues: null,
        dateModified: '2022-12-04T17:30:06.300587Z',
      },
      equipmentTypeId: -2,
      equipmentType: {
        id: -2,
        name: 'pump',
        description: 'Vertical Pump',
        equipment: {},
        dateModified: '2022-11-23T22:59:04.099104Z',
      },
      name: '',
      longitude: null,
      latitude: null,
      voltageRating: 480.000000,
      powerFactor: 0.920000,
      amperageLevels: null,
      ioValues: {
        low_suction_pressure: {
          id: 59,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -1,
          ioValueType: {
            id: -1,
            name: 'alarm',
            units: null,
            description: 'Alarm Sensor',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag101',
          address: null,
          dataType: null,
          name: 'low_suction_pressure',
          description: 'low_suction_pressure alarm',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618273Z',
        },
        high_seal_temperature: {
          id: 60,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -1,
          ioValueType: {
            id: -1,
            name: 'alarm',
            units: null,
            description: 'Alarm Sensor',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag121',
          address: null,
          dataType: null,
          name: 'high_seal_temperature',
          description: 'high_seal_temperature alarm',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618272Z',
        },
        start: {
          id: 61,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -6,
          ioValueType: {
            id: -6,
            name: 'toggle_on',
            units: null,
            description: 'Set to 1',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag147',
          address: null,
          dataType: null,
          name: 'start',
          description: 'start toggle_on',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618272Z',
        },
        stop: {
          id: 62,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -6,
          ioValueType: {
            id: -6,
            name: 'toggle_on',
            units: null,
            description: 'Set to 1',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag179',
          address: null,
          dataType: null,
          name: 'stop',
          description: 'stop toggle_on',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618271Z',
        },
        current: {
          id: 63,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -16,
          ioValueType: {
            id: -16,
            name: 'amps',
            units: 'amps',
            description: 'Current in Amperes',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag215',
          address: null,
          dataType: null,
          name: 'current',
          description: 'current amps',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618271Z',
        },
        run_time: {
          id: 65,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -20,
          ioValueType: {
            id: -20,
            name: 'hours',
            units: 'hrs',
            description: 'Elapsed Time',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag249',
          address: null,
          dataType: null,
          name: 'run_time',
          description: 'run_time hours',
          value: 0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.61827Z',
        },
        temperature_alternate: {
          id: 771,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -27,
          ioValueType: {
            id: -27,
            name: 'celsius',
            units: '°C',
            description: 'International Temperature',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: null,
          address: null,
          dataType: null,
          name: 'temperature_alternate',
          description: 'temperature_alternate celsius',
          value: 15.610000,
          source: 1,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-12-08T17:41:28.058474Z',
        },
      },
      status: 0,
      textStatus: null,
      textStatusKey: null,
      wetbulb: null,
      dateModified: '2022-11-23T17:59:06.42082Z',
    },
    P7: {
      id: 14,
      locationId: 3,
      plcId: 2,
      plc: {
        id: 2,
        name: 'Booster',
        channel: 'Channel101',
        ipAddress: '<10.75.10.107>',
        model: null,
        isSimulated: false,
        hasError: false,
        isEnabled: true,
        ioValues: null,
        dateModified: '2022-12-04T17:30:06.300587Z',
      },
      equipmentTypeId: -2,
      equipmentType: {
        id: -2,
        name: 'pump',
        description: 'Vertical Pump',
        equipment: {},
        dateModified: '2022-11-23T22:59:04.099104Z',
      },
      name: '',
      longitude: null,
      latitude: null,
      voltageRating: 480.000000,
      powerFactor: 0.920000,
      amperageLevels: null,
      ioValues: {
        low_suction_pressure: {
          id: 59,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -1,
          ioValueType: {
            id: -1,
            name: 'alarm',
            units: null,
            description: 'Alarm Sensor',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag101',
          address: null,
          dataType: null,
          name: 'low_suction_pressure',
          description: 'low_suction_pressure alarm',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618273Z',
        },
        high_seal_temperature: {
          id: 60,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -1,
          ioValueType: {
            id: -1,
            name: 'alarm',
            units: null,
            description: 'Alarm Sensor',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag121',
          address: null,
          dataType: null,
          name: 'high_seal_temperature',
          description: 'high_seal_temperature alarm',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618272Z',
        },
        start: {
          id: 61,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -6,
          ioValueType: {
            id: -6,
            name: 'toggle_on',
            units: null,
            description: 'Set to 1',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag147',
          address: null,
          dataType: null,
          name: 'start',
          description: 'start toggle_on',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618272Z',
        },
        stop: {
          id: 62,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -6,
          ioValueType: {
            id: -6,
            name: 'toggle_on',
            units: null,
            description: 'Set to 1',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag179',
          address: null,
          dataType: null,
          name: 'stop',
          description: 'stop toggle_on',
          value: 0.0,
          source: 0,
          isTrendable: false,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618271Z',
        },
        current: {
          id: 63,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -16,
          ioValueType: {
            id: -16,
            name: 'amps',
            units: 'amps',
            description: 'Current in Amperes',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag215',
          address: null,
          dataType: null,
          name: 'current',
          description: 'current amps',
          value: 0.0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.618271Z',
        },
        run_time: {
          id: 65,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -20,
          ioValueType: {
            id: -20,
            name: 'hours',
            units: 'hrs',
            description: 'Elapsed Time',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: 'Tag249',
          address: null,
          dataType: null,
          name: 'run_time',
          description: 'run_time hours',
          value: 0,
          source: 0,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-11-23T17:59:06.61827Z',
        },
        temperature_alternate: {
          id: 771,
          plc: null,
          location: null,
          equipmentId: 14,
          ioValueTypeId: -27,
          ioValueType: {
            id: -27,
            name: 'celsius',
            units: '°C',
            description: 'International Temperature',
            dateModified: '2022-11-23T22:59:04.099104Z',
          },
          setpointRange: null,
          tagName: null,
          address: null,
          dataType: null,
          name: 'temperature_alternate',
          description: 'temperature_alternate celsius',
          value: 15.610000,
          source: 1,
          isTrendable: true,
          calculationType: null,
          calculationIOValueIds: null,
          dateModified: '2022-12-08T17:41:28.058474Z',
        },
      },
      status: 0,
      textStatus: null,
      textStatusKey: null,
      wetbulb: null,
      dateModified: '2022-11-23T17:59:06.42082Z',
    },
  };

  if (!locationEquipment12) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }
  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" border backgroundColor={theme.backgroundColor} label="Booster Pumphouse">
            <InfoControlRow>
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[2])}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
              />
              {/* <Info
              title="Instrument Air"
              statistic={locationEquipment12?.IA?.status ? 'Enabled' : 'Disabled'}
              color="grey"
              label=""
            /> */}
              <Info
                title="Instrument Air"
                statistic={locationEquipment12?.IA?.ioValues?.low_air_pressure?.value === 1 ? 'Enabled' : 'Low Pressure'}
                color={locationEquipment12?.IA?.ioValues?.low_air_pressure?.value === 1 ? theme.onGreen : theme.alarm}
                label=""
              />
              <AlarmReset
                title="System reset"
                buttonText="Reset"
                alarmData={locationEquipment12?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <VFDPumpControl
                title="Control Valve CV1 PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="64px"
                pumpData={{ id: locationEquipment12?.['CV1 PID'].id }}
                pidData={locationEquipment12?.['CV1 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <VFDPumpControl
                title="Control Valve CV2 PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="64px"
                pumpData={{ id: locationEquipment12?.['CV2 PID'].id }}
                pidData={locationEquipment12?.['CV2 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <VFDPumpControl
                title="Bypass Valve BV1 PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="64px"
                pumpData={{ id: locationEquipment12?.['BV1 PID'].id }}
                pidData={locationEquipment12?.['BV1 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <VFDPumpControl
                title="Bypass Valve BV2 PID"
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="64px"
                pumpData={{ id: locationEquipment12?.['BV2 PID'].id }}
                pidData={locationEquipment12?.['BV2 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <MobileStatTable
                title="Water System Metrics"
                stats={[
                  {
                    description: 'High Press. Discharge Water PSI',
                    stat: locationEquipment12?.Other?.ioValues[IOValueKeys.highDischargeWaterPressure]?.value,
                    label: locationEquipment12?.Other?.ioValues[IOValueKeys.highDischargeWaterPressure]?.ioValueType?.units,
                  },
                  {
                    description: 'High Press. Back Water PSI',
                    stat: locationEquipment12?.Other?.ioValues[IOValueKeys.highBackWaterPressure]?.value,
                    label: locationEquipment12?.Other?.ioValues[IOValueKeys.highBackWaterPressure]?.ioValueType?.units,
                  },
                  {
                    description: 'High Press. Suction Water PSI',
                    stat: locationEquipment12?.Other?.ioValues[IOValueKeys.highSuctionPressure]?.value,
                    label: locationEquipment12?.Other?.ioValues[IOValueKeys.highSuctionPressure]?.ioValueType?.units,
                  },
                  {
                    description: 'Med. Press. Discharge Water PSI',
                    stat: locationEquipment12?.Other?.ioValues[IOValueKeys.medDischargeWaterPressure]?.value,
                    label: locationEquipment12?.Other?.ioValues[IOValueKeys.medDischargeWaterPressure]?.ioValueType?.units,
                  },
                  {
                    description: 'Med. Press. Back Water PSI',
                    stat: locationEquipment12?.Other?.ioValues[IOValueKeys.medBackWaterPressure]?.value,
                    label: locationEquipment12?.Other?.ioValues[IOValueKeys.medBackWaterPressure]?.ioValueType?.units,
                  },
                  {
                    description: 'Med. Press. Suction Water PSI',
                    stat: locationEquipment12?.Other?.ioValues[IOValueKeys.medSuctionWaterPressure]?.value,
                    label: locationEquipment12?.Other?.ioValues[IOValueKeys.medSuctionWaterPressure]?.ioValueType?.units,
                  },
                ]}
              />
            </InfoControlRow>
            <PumpsRowCustom height="146px">
              <PipePlaceholder hidden />
              <div css={styles.flexRow}>
                <div css={css`width: 0px; margin-right: 33px;`}>
                  <div css={css`display: flex; height: 100%; flex-direction: column;`}>
                    <div css={css`height: 20px;`}>
                      <PipePlaceholder capBefore minWidth="0px" capLabel="To High Press. Manifold" capLabelLeft="-55px" capLabelTop="98px" />
                    </div>
                    <div css={[styles.hiddenMobile, css`width: 51px; display: flex; justify-content: center;`]}>
                      <Statistic
                        label={locationEquipment12?.Other?.ioValues[IOValueKeys.highDischargeWaterPressure]?.ioValueType?.units}
                        border
                        statistic={locationEquipment12?.Other?.ioValues[IOValueKeys.highDischargeWaterPressure]?.value}
                      />
                    </div>
                    <ValveConnect
                      reverseValve
                      minWidth="0px"
                      percent
                      percentTop="55px"
                      percentLeft="-15px"
                      nameTop="7px"
                      nameLeft="-70px"
                      valveData={{ id: locationEquipment12?.['CV1 PID'].id, state: locationEquipment12?.Other?.ioValues?.[IOValueKeys.cv1Position]?.value, name: 'CV1' }}
                      binary={false}
                      valveStateColorKeys={{
                        0: theme.offRed,
                        100: theme.onGreen,
                      }}
                    />
                    <PipePlaceholder minWidth="0px" />
                  </div>
                </div>
                <div css={css`width: 28px;`}>
                  <PipePlaceholder horizontalBelow minWidth="0px" />
                </div>
                <Pump
                  percent
                  onPipe
                  pipeBelow="middle"
                  componentMinWidth="140px"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment12?.P6}
                  otherData={locationEquipment12?.Other}
                  controlPositionTop="-32px"
                  controlPositionLeft="-10px"
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    speed: 'temperature',
                    start: 'start',
                    stop: 'stop',
                    localRemote: 'local_remote',
                    local: 'local_remote',
                    remote: 'local_remote',
                    drain: 'drain',
                  }}
                  alarms={[
                    locationEquipment12?.P6?.ioValues?.[IOValueKeys.lowSuctionPressure],
                    locationEquipment12?.P6?.ioValues?.[IOValueKeys.highSealTemperature],
                  ]}
                  stats={[
                    {
                      stat: locationEquipment12?.P6?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment12?.P6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment12?.P6?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment12?.P6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <div css={[styles.flexCol, css`min-width: 175px;`]}>
                <div css={css`height: 160px;`} />
                <PipePlaceholder pipeAbove="left" />
              </div>
              <div css={styles.flexRow}>
                <div css={css`width: 0px; margin-right: 33px;`}>
                  <div css={css`display: flex; height: 100%; flex-direction: column;`}>
                    <div css={css`height: 20px;`}>
                      <PipePlaceholder capBefore minWidth="0px" capLabel="To Medium Press. Manifold" capLabelLeft="-69px" capLabelTop="110px" />
                    </div>
                    <div css={[styles.hiddenMobile, css`width: 51px; display: flex; justify-content: center;`]}>
                      <Statistic
                        label={locationEquipment12?.Other?.ioValues[IOValueKeys.medDischargeWaterPressure]?.ioValueType?.units}
                        border
                        statistic={locationEquipment12?.Other?.ioValues[IOValueKeys.medDischargeWaterPressure]?.value}
                      />
                    </div>
                    <ValveConnect
                      reverseValve
                      minWidth="0px"
                      percent
                      percentTop="55px"
                      percentLeft="-15px"
                      nameTop="7px"
                      nameLeft="-70px"
                      valveData={{ id: locationEquipment12?.['CV2 PID'].id, state: locationEquipment12?.Other?.ioValues?.[IOValueKeys.cv2Position]?.value, name: 'CV2' }}
                      binary={false}
                      valveStateColorKeys={{
                        0: theme.offRed,
                        100: theme.onGreen,
                      }}
                    />
                    <PipePlaceholder minWidth="0px" />
                  </div>
                </div>
                <div css={css`width: 28px;`}>
                  <PipePlaceholder horizontalBelow minWidth="0px" />
                </div>
                <Pump
                  percent
                  onPipe
                  pipeBelow="middle"
                  componentMinWidth="140px"
                  controlPositionTop="-32px"
                  controlPositionLeft="-10px"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment12?.P9}
                  otherData={locationEquipment12?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    localRemote: 'local_remote',
                    local: 'local_remote',
                    remote: 'local_remote',
                    drain: 'drain',
                  }}
                  alarms={[
                    locationEquipment12?.P9?.ioValues?.[IOValueKeys.lowSuctionPressure],
                  ]}
                  stats={[
                    {
                      stat: locationEquipment12?.P9?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment12?.P9?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment12?.P9?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment12?.P9?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <div css={css`display: flex; flex-direction: column; height: 100%; width: 40px;`}>
                <div css={css`height: 140px;`} />
                <PipePlaceholder pipeAbove="left" minWidth="0px" />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="146px">
              <div css={styles.flexRow}>
                <PipePlaceholder hidden minWidth="0px" />
                <div css={[styles.flexCol, css`margin-right: -24px;`]}>
                  <ValveConnect
                    capBefore
                    minWidth="0px"
                    horizontal
                    percent
                    percentTop="24px"
                    percentLeft="3px"
                    nameTop="-63px"
                    nameLeft="7px"
                    valveData={{ id: locationEquipment12?.['BV1 PID'].id, state: locationEquipment12?.Other?.ioValues?.[IOValueKeys.bv1Position]?.value, name: 'BV1' }}
                    binary={false}
                    valveStateColorKeys={{
                      0: theme.offRed,
                      100: theme.onGreen,
                    }}
                  />
                </div>
              </div>
              <div css={styles.flexRow}>
                <div css={css`width: 51px; margin-right: -18px;`}>
                  <div css={css`display: flex; height: 100%; flex-direction: column;`}>
                    <div css={css`height: 20px;`}>
                      <PipePlaceholder minWidth="0px" />
                    </div>
                    <div css={[styles.hiddenMobile, css`width: 51px; display: flex; justify-content: center;`]}>
                      <Statistic
                        label={locationEquipment12?.Other?.ioValues[IOValueKeys.highBackWaterPressure]?.ioValueType?.units}
                        border
                        statistic={locationEquipment12?.Other?.ioValues[IOValueKeys.highBackWaterPressure]?.value}
                      />
                    </div>
                    <PipePlaceholder pipeBelow="right" minWidth="0px" />
                  </div>
                </div>
                <div css={css`width: 28px;`}>
                  <PipePlaceholder horizontalBelow minWidth="0px" />
                </div>
                <Pump
                  viewOnly
                  percent
                  onPipe
                  pipeBelow="middle"
                  componentMinWidth="140px"
                  setIOValue={props.setIOValue}
                  pumpData={fakeLocationEquipment?.P5}
                  otherData={fakeLocationEquipment?.Other}
                  controlPositionTop="-32px"
                  controlPositionLeft="-10px"
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    speed: 'temperature',
                    start: 'start',
                    stop: 'stop',
                    localRemote: 'local_remote',
                    local: 'local_remote',
                    remote: 'local_remote',
                    drain: 'drain',
                  }}
                  alarms={[
                    fakeLocationEquipment?.P5?.ioValues?.[IOValueKeys.lowSuctionPressure],
                    fakeLocationEquipment?.P5?.ioValues?.[IOValueKeys.highSealTemperature],
                  ]}
                  stats={[
                    {
                      stat: fakeLocationEquipment?.P5?.ioValues?.[IOValueKeys.amps]?.value,
                      label: fakeLocationEquipment?.P5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: fakeLocationEquipment?.P5?.ioValues?.[IOValueKeys.hours]?.value,
                      label: fakeLocationEquipment?.P5?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={styles.redBox} />
              </div>
              <div css={styles.flexRow}>
                <div css={css`width: 51px; margin-right: -18px;`}>
                  <div css={css`display: flex; height: 100%; flex-direction: row;`}>
                    <div css={css`width: 0px;`}>
                      <PipePlaceholder horizontalBelow minWidth="18px" />
                    </div>
                    <PipePlaceholder minWidth="0px" />
                  </div>
                </div>
                <div css={css`width: 30px;`}>
                  <PipePlaceholder horizontalCenter minWidth="0px" />
                </div>
                <div css={[styles.hiddenMobile, css`display: flex; flex-direction: column; justify-content: center;`]}>
                  <Statistic
                    label={locationEquipment12?.Other?.ioValues[IOValueKeys.highSuctionPressure]?.ioValueType?.units}
                    border
                    statistic={locationEquipment12?.Other?.ioValues[IOValueKeys.highSuctionPressure]?.value}
                  />
                </div>
                <ValveConnect
                  horizontal
                  minWidth="0px"
                  manual
                  readOnly
                  nameTop="7px"
                  nameLeft="-55px"
                  // valveStateColorKeys={{
                  //   0: theme.onGreen,
                  //   1: theme.offRed,
                  // }}
                  // valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib3]?.value, name: 'IB3' }}
                />
              </div>
              <div css={styles.flexRow}>
                <div css={css`width: 0px;`}>
                  <PipePlaceholder horizontalCenter minWidth="18px" />
                </div>
                <div css={css`width: 0px; margin-right: 33px;`}>
                  <div css={css`display: flex; height: 100%; flex-direction: column;`}>
                    <PipePlaceholder minWidth="0px" />
                    <div css={[styles.hiddenMobile, css`width: 51px; display: flex; justify-content: center;`]}>
                      <Statistic
                        label={locationEquipment12?.Other?.ioValues[IOValueKeys.medBackWaterPressure]?.ioValueType?.units}
                        border
                        statistic={locationEquipment12?.Other?.ioValues[IOValueKeys.medBackWaterPressure]?.value}
                      />
                    </div>
                    <div css={css`height: 30px;`}>
                      <PipePlaceholder minWidth="0px" />
                    </div>
                  </div>
                </div>
                <div css={css`width: 28px;`}>
                  <PipePlaceholder horizontalBelow minWidth="0px" />
                </div>
                <Pump
                  viewOnly
                  percent
                  onPipe
                  pipeBelow="middle"
                  componentMinWidth="140px"
                  setIOValue={props.setIOValue}
                  pumpData={fakeLocationEquipment?.P8}
                  otherData={fakeLocationEquipment?.Other}
                  controlPositionTop="-32px"
                  controlPositionLeft="-10px"
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    speed: 'temperature',
                    start: 'start',
                    stop: 'stop',
                    localRemote: 'local_remote',
                    local: 'local_remote',
                    remote: 'local_remote',
                    drain: 'drain',
                  }}
                  alarms={[
                    fakeLocationEquipment?.P8?.ioValues?.[IOValueKeys.lowSuctionPressure],
                    fakeLocationEquipment?.P8?.ioValues?.[IOValueKeys.highSealTemperature],
                  ]}
                  stats={[
                    {
                      stat: fakeLocationEquipment?.P8?.ioValues?.[IOValueKeys.amps]?.value,
                      label: fakeLocationEquipment?.P8?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: fakeLocationEquipment?.P8?.ioValues?.[IOValueKeys.hours]?.value,
                      label: fakeLocationEquipment?.P8?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={styles.redBox} />
              </div>
              <div css={css`display: flex; height: 100%; flex-direction: row; width: 40px;`}>
                <div css={css`width: 0px;`}>
                  <PipePlaceholder horizontalBelow minWidth="18px" />
                </div>
                <div css={css`margin-left: -11px;`}>
                  <PipePlaceholder minWidth="0px" />
                </div>
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="146px">
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={styles.flexRow}>
                <ValveConnect
                  minWidth="0px"
                  manual
                  readOnly
                  nameTop="7px"
                  nameLeft="-55px"
                  // valveStateColorKeys={{
                  //   0: theme.onGreen,
                  //   1: theme.offRed,
                  // }}
                  // valveData={{ state: locationEquipment3?.Other?.ioValues?.[IOValueKeys.ib3]?.value, name: 'IB3' }}
                />
                <div css={[styles.flexCol, css`margin-right: -24px;`]}>
                  <ValveConnect
                    capBefore
                    minWidth="0px"
                    horizontal
                    percent
                    percentTop="24px"
                    percentLeft="3px"
                    nameTop="-63px"
                    nameLeft="7px"
                    valveData={{ id: locationEquipment12?.['BV2 PID'].id, state: locationEquipment12?.Other?.ioValues?.[IOValueKeys.bv2Position]?.value, name: 'BV2' }}
                    binary={false}
                    valveStateColorKeys={{
                      0: theme.offRed,
                      100: theme.onGreen,
                    }}
                  />
                </div>
              </div>
              <div css={styles.flexRow}>
                <div css={css`width: 51px; margin-right: -18px;`}>
                  <PipePlaceholder pipeBelow="right" minWidth="0px" />
                </div>
                <div css={css`width: 28px;`}>
                  <PipePlaceholder horizontalBelow minWidth="0px" />
                </div>
                <Pump
                  viewOnly
                  percent
                  onPipe
                  pipeBelow="middle"
                  componentMinWidth="140px"
                  setIOValue={props.setIOValue}
                  pumpData={fakeLocationEquipment?.P7}
                  otherData={fakeLocationEquipment?.Other}
                  controlPositionTop="-32px"
                  controlPositionLeft="-10px"
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    speed: 'temperature',
                    start: 'start',
                    stop: 'stop',
                    localRemote: 'local_remote',
                    local: 'local_remote',
                    remote: 'local_remote',
                    drain: 'drain',
                  }}
                  alarms={[
                    fakeLocationEquipment?.P7?.ioValues?.[IOValueKeys.lowSuctionPressure],
                    fakeLocationEquipment?.P7?.ioValues?.[IOValueKeys.highSealTemperature],
                  ]}
                  stats={[
                    {
                      stat: fakeLocationEquipment?.P7?.ioValues?.[IOValueKeys.amps]?.value,
                      label: fakeLocationEquipment?.P7?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: fakeLocationEquipment?.P7?.ioValues?.[IOValueKeys.hours]?.value,
                      label: fakeLocationEquipment?.P7?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={styles.redBox} />
              </div>
              <div css={css`display: flex; height: 100%; flex-direction: row; width: 40px;`}>
                <div css={css`width: 0px;`}>
                  <PipePlaceholder horizontalBelow minWidth="18px" />
                </div>
                <div css={css`margin-left: -11px;`}>
                  <PipePlaceholder minWidth="0px" />
                </div>
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="45px">
              <PipePlaceholder capBefore horizontalBelow size="large" capLabel="From New River" capLabelTop="-19px" capLabelLeft="32px" />
              <div css={styles.flexRow}>
                <PipePlaceholder minWidth="0px" horizontalBelow size="large" />
                <div css={[styles.hiddenMobile, css`display: flex; flex-direction: column; justify-content: flex-end;`]}>
                  <Statistic
                    label={locationEquipment12?.Other?.ioValues[IOValueKeys.medSuctionWaterPressure]?.ioValueType?.units}
                    border
                    size="large"
                    statistic={locationEquipment12?.Other?.ioValues[IOValueKeys.medSuctionWaterPressure]?.value}
                  />
                </div>
                <PipePlaceholder minWidth="0px" horizontalBelow size="large" />
              </div>
              <PipePlaceholder pipeBelow="middle" pipeSizeBelow="large" />
              <PipePlaceholder horizontalBelow
                size="large"
              />
              <div css={css`display: flex; height: 100%; width: 40px;`}>
                <div css={css`margin-left: -11px;`}>
                  <PipePlaceholder minWidth="0px" pipeBelow="left" pipeSizeBelow="large" />
                </div>
              </div>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 100%;
      min-width: 175px;
      position: relative;
    `,
    redBox: css`
      border: 2px dashed red;
      z-index: 3;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 5px;
      left: 48px;
      width: 83%;
      height: 83%;
    `,
    flexCol: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

BoosterScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLC: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

BoosterScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLC })(BoosterScreen);
