import axios from 'axios';
import { ROOT_URL, TOKEN_STRING } from './Shared';

export const calcDaysAgo = (date) => {
  const today = new Date();
  const otherDay = date.toDate();
  const msPerDay = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(otherDay.getYear(), otherDay.getMonth(), otherDay.getDate());
  const utc2 = Date.UTC(today.getYear(), today.getMonth(), today.getDate());

  return Math.floor((utc2 - utc1) / msPerDay);
};

// Also do a log table one based on start date vs current date,
// if more than 7 days in past, use 10min else use 1min
// i.e ${ROOT_URL}/logData/10min/{startDate}/{endDate}
// or ${ROOT_URL}/logData/1min/{startDate}/{endDate}
export async function sendReportRequest(startDateString, endDateString, report, startDay) {
  const token = localStorage.getItem(TOKEN_STRING);
  let route = 'TrailReport';
  let filenamePrefix = 'TrailReport';

  // Choose which route we are requesting data from
  if (report === 1) {
    if (calcDaysAgo(startDay) > 7) {
      // If start date is more than a week ago, use 10min data
      route = 'LogData/10min';
      filenamePrefix = '10minLogData'; // Just matching the backend naming convention here
    } else {
      route = 'LogData/1min';
      filenamePrefix = '1minLogData'; // Just matching the backend naming convention here
    }
  } else if (report === 2) {
    route = 'NoteReport';
    filenamePrefix = 'NoteReport'; // Just matching the backend naming convention here
  } else if (report === 3) {
    route = 'energyReport';
    filenamePrefix = 'energyReport'; // Just matching the backend naming convention here
  }

  const response = await axios({
    method: 'get',
    responseType: 'blob',
    url: `${ROOT_URL}/reports/${route}/${startDateString}/${endDateString}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(response.data);
  const currentDate = new Date();
  link.download = `${filenamePrefix}_${currentDate.toLocaleString('en-GB')}.xlsx`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return (response.status);

  // .then(blob => URL.createObjectURL(blob))
  // .then(url => {
  //     window.open(url, '_blank');
  //     URL.revokeObjectURL(url);
  // });
}
